
















































import { Vue, Component, Watch } from "vue-property-decorator";
import Rview from "@/components/routerView/index.vue";
import Bread from "@/components/bread/index.vue";
import { OtherModule } from "@/store/other";
@Component({
  components: {
    Rview,
    Bread,
  },
})
export default class extends Vue {
  showList = true;
  get is_mobile() {
    return OtherModule.is_mobile;
  }
  @Watch("$route", { immediate: true })
  watch_route(res: any) {
    this.showList = res.params.group_id ? false : true;
  }
}
