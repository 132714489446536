





























import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { RouteRecord } from "vue-router";

interface new_list {
	title:string
	to:string
}

@Component
export default class extends Vue {
    list: RouteRecord[] = [];

    @Watch("$route")
    getRouterList() {
        let allList = this.$route.matched.slice(1);
		this.list = allList.filter((item) => {
                    if (item.meta && item.meta.title) {
                        if(this.$route.params.id){
                            item.path = item.path.replace(/:id/i,this.$route.params.id)
                        }
                        if(this.$route.params.chapterId){
                            item.path = item.path.replace(/:chapterId/i,this.$route.params.chapterId)
                        }
                        return true;
                    }
                });
	}



	@Prop({ type:Array,required:false,default:null })
	new_list !: new_list[] | null

    created() {
        this.getRouterList();
	}
}
